import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const TisvildelejeMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 414 768'>
			<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M207 37.24v678.37'/>
			<g className='stations' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<g className='start-stop'>
					<text className='caption' transform='translate(224.09 42.19)' fontSize='14' fill='#00275e' fontWeight='700'>Tisvildeleje</text>
					<circle className='dot' cx='207' cy='37.24' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
				</g>
				<g className='stop'>
					<text id='caption-2' className='caption' transform='translate(224.09 103.87)' fontSize='14' fill='#00275e' fontWeight='700'>Godhavn</text>
					<g id='dot-2' className='dot'>
						<circle cx='207' cy='98.91' r='7' fill='#fff'/>
						<circle cx='207' cy='98.91' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='98.91' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-2' className='stop'>
					<text id='caption-3' className='caption' transform='translate(224.09 165.54)' fontSize='14' fill='#00275e' fontWeight='700'>Holløse</text>
					<g id='dot-3' className='dot'>
						<circle cx='207' cy='160.58' r='7' fill='#fff'/>
						<circle cx='207' cy='160.58' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='160.58' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-3' className='stop'>
					<text id='caption-4' className='caption' transform='translate(224.09 227.21)' fontSize='14' fill='#00275e' fontWeight='700' letterSpacing='-.05em'>Vejby</text>
					<circle id='dot-4' className='dot' cx='207' cy='222.25' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
				</g>
				<g id='stop-4' className='stop'>
					<text id='caption-5' className='caption' transform='translate(224.09 288.87)' fontSize='14' fill='#00275e' fontWeight='700'>Ørby</text>
					<g id='dot-5' className='dot'>
						<circle cx='207' cy='283.92' r='7' fill='#fff'/>
						<circle cx='207' cy='283.92' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='283.92' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-5' className='stop'>
					<text id='caption-6' className='caption' transform='translate(224.09 350.54)' fontSize='14' fill='#00275e' fontWeight='700'>Troldebakkerne</text>
					<g id='dot-6' className='dot'>
						<circle cx='207' cy='345.59' r='7' fill='#fff'/>
						<circle cx='207' cy='345.59' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='345.59' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-6' className='stop'>
					<text id='caption-7' className='caption' transform='translate(224.09 412.22)' fontSize='14' fill='#00275e' fontWeight='700'>Helsinge</text>
					<circle id='dot-7' className='dot' cx='207' cy='407.26' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
				</g>
				<g id='stop-7' className='stop'>
					<text id='caption-8' className='caption' transform='translate(224.09 473.89)' fontSize='14' fill='#00275e' fontWeight='700'>Duemose</text>
					<g id='dot-8' className='dot'>
						<circle cx='207' cy='468.93' r='7' fill='#fff'/>
						<circle cx='207' cy='468.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='468.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g className='intermediate-stop'>
					<text id='caption-9' className='caption' transform='translate(224.09 535.55)' fontSize='14' fill='#00275e' fontWeight='700'>Kagerup</text>
					<g id='dot-9' className='dot'>
						<circle cx='207' cy='530.6' r='7' fill='#fff'/>
						<circle cx='207' cy='530.6' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='530.6' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-8' className='stop'>
					<text id='caption-10' className='caption' transform='translate(224.09 597.22)' fontSize='14' fill='#00275e' fontWeight='700'>Gribsø</text>
					<g id='dot-10' className='dot'>
						<circle cx='207' cy='592.27' r='7' fill='#fff'/>
						<circle cx='207' cy='592.27' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
						<circle cx='207' cy='592.27' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
					</g>
				</g>
				<g id='stop-9' className='stop'>
					<text id='caption-11' className='caption' transform='translate(224.09 658.89)' fontSize='14' fill='#00275e' fontWeight='700'>Slotspavillonen</text>
					<circle id='dot-11' className='dot' cx='207' cy='653.94' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
				</g>
				<g className='end-stop'>
					<text id='caption-12' className='caption' transform='translate(224.09 720.56)' fontSize='14' fill='#00275e' fontWeight='700'>Hillerød</text>
					<circle id='dot-12' className='dot' cx='207' cy='715.61' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
				</g>
			</g>
		</svg>
	);
};

export default TisvildelejeMobile;
