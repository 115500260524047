import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Lillenord = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1366 768'>
			<g id='lillenord' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M145.04 361.93h958.61'/>
				<g className='stations'>
					<g className='start-stop'>
						<circle className='dot' cx='145.04' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text className='caption' transform='rotate(-45 490.348 -12.729)' fontSize='14' fill='#00275e' fontWeight='700'>Hillerød</text>
					</g>
					<g className='stop'>
						<text id='caption-2' className='caption' transform='rotate(-45 549.998 -156.736)' fontSize='14' fill='#00275e' fontWeight='700'>Grønholt</text>
						<g id='dot-2' className='dot'>
							<circle cx='264.86' cy='361.93' r='7' fill='#fff'/>
							<circle cx='264.86' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='264.86' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-2' className='stop'>
						<text id='caption-3' className='caption' transform='rotate(-45 609.913 -301.384)' fontSize='14' fill='#00275e' fontWeight='700'>Kratbjerg</text>
						<g id='dot-3' className='dot'>
							<circle cx='384.69' cy='361.93' r='7' fill='#fff'/>
							<circle cx='384.69' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='384.69' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-3' className='stop'>
						<text id='caption-4' className='caption' transform='rotate(-45 669.823 -446.02)' fontSize='14' fill='#00275e' fontWeight='700'>Fredensborg</text>
						<circle id='dot-4' className='dot' cx='503.94' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-4' className='stop'>
						<text id='caption-5' className='caption' transform='rotate(-45 729.738 -590.667)' fontSize='14' fill='#00275e' fontWeight='700'>Langerød</text>
						<g id='dot-5' className='dot'>
							<circle cx='624.35' cy='361.93' r='7' fill='#fff'/>
							<circle cx='624.35' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='624.35' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-5' className='stop'>
						<text id='caption-6' className='caption' transform='rotate(-45 789.653 -735.315)' fontSize='14' fill='#00275e' fontWeight='700'>Kvistgård</text>
						<circle id='dot-6' className='dot' cx='743.88' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-6' className='stop'>
						<text id='caption-7' className='caption' transform='rotate(-45 849.563 -879.95)' fontSize='14' fill='#00275e' fontWeight='700'>Mørdrup</text>
						<g id='dot-7' className='dot'>
							<circle cx='864' cy='361.93' r='7' fill='#fff'/>
							<circle cx='864' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='864' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-7' className='stop'>
						<text id='caption-8' className='caption' transform='rotate(-45 909.478 -1024.598)' fontSize='14' fill='#00275e' fontWeight='700'>Snekkersten</text>
						<circle id='dot-8' className='dot' cx='983.83' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='end-stop'>
						<circle id='dot-9' className='dot' cx='1103.65' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-9' className='caption' transform='rotate(-45 968.343 -1166.71)' fontSize='14' fill='#00275e' fontWeight='700'>Helsingør</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Lillenord;
