import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Odsherred = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1366 768'>
			<g id='odsherred' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M145.04 361.93h958.61'/>
				<g className='stations'>
					<g className='end-stop'>
						<circle className='dot' cx='1103.65' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text className='caption' transform='rotate(-45 969.393 -1169.246)' fontSize='14' fill='#00275e' fontWeight='700'>Holbæk</text>
					</g>
					<g className='stop'>
						<g id='dot-2' className='dot'>
							<circle cx='1035.18' cy='361.93' r='7' fill='#fff'/>
							<circle cx='1035.18' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='1035.18' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-2' className='caption' transform='rotate(-45 935.153 -1086.583)' fontSize='14' fill='#00275e' fontWeight='700'>Stenhus</text>
					</g>
					<g id='stop-2' className='stop'>
						<g id='dot-3' className='dot'>
							<circle cx='966.71' cy='361.93' r='7' fill='#fff'/>
							<circle cx='966.71' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='966.71' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-3' className='caption' transform='rotate(-45 900.918 -1003.932)' fontSize='14' fill='#00275e' fontWeight='700'>Ny Hagested</text>
					</g>
					<g id='stop-3' className='stop'>
						<g id='dot-4' className='dot'>
							<circle cx='898.24' cy='361.93' r='7' fill='#fff'/>
							<circle cx='898.24' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='898.24' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-4' className='caption' transform='rotate(-45 866.683 -921.282)' fontSize='14' fill='#00275e' fontWeight='700'>Gislinge</text>
					</g>
					<g id='stop-4' className='stop'>
						<g id='dot-5' className='dot'>
							<circle cx='829.76' cy='361.93' r='7' fill='#fff'/>
							<circle cx='829.76' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='829.76' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-5' className='caption' transform='rotate(-45 832.448 -838.631)' fontSize='14' fill='#00275e' fontWeight='700'>Svinninge</text>
					</g>
					<g id='stop-5' className='stop'>
						<g id='dot-6' className='dot'>
							<circle cx='761.29' cy='361.93' r='7' fill='#fff'/>
							<circle cx='761.29' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='761.29' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-6' className='caption' transform='rotate(-45 798.208 -755.968)' fontSize='14' fill='#00275e' fontWeight='700'>Hørve</text>
					</g>
					<g id='stop-6' className='stop'>
						<g id='dot-7' className='dot'>
							<circle cx='692.82' cy='361.93' r='7' fill='#fff'/>
							<circle cx='692.82' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='692.82' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-7' className='caption' transform='rotate(-45 763.973 -673.318)' fontSize='14' fill='#00275e' fontWeight='700'>Fårevejle</text>
					</g>
					<g id='stop-7' className='stop'>
						<text id='caption-8' className='caption' transform='rotate(-45 729.738 -590.667)' fontSize='14' fill='#00275e' fontWeight='700'>Asnæs</text>
						<circle id='dot-8' className='dot' cx='624.35' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-8' className='stop'>
						<g id='dot-9' className='dot'>
							<circle cx='555.87' cy='361.93' r='7' fill='#fff'/>
							<circle cx='555.87' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='555.87' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text id='caption-9' className='caption' transform='rotate(-45 695.503 -508.017)' fontSize='14' fill='#00275e' fontWeight='700'>Grevinge</text>
					</g>
					<g id='stop-9' className='stop'>
						<text id='caption-10' className='caption' transform='rotate(-45 661.263 -425.354)' fontSize='14' fill='#00275e' fontWeight='700' letterSpacing='.01em'>Vig</text>
						<g id='dot-10' className='dot'>
							<circle cx='487.4' cy='361.93' r='7' fill='#fff'/>
							<circle cx='487.4' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='487.4' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-10' className='stop'>
						<text id='caption-11' className='caption' transform='rotate(-45 627.028 -342.703)' fontSize='14' fill='#00275e' fontWeight='700'>Nørre Asminderup</text>
						<g id='dot-11' className='dot'>
							<circle cx='418.93' cy='361.93' r='7' fill='#fff'/>
							<circle cx='418.93' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='418.93' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-11' className='stop'>
						<text id='caption-12' className='caption' transform='rotate(-45 592.793 -260.053)' fontSize='14' fill='#00275e' fontWeight='700'>Sommerland Sjælland</text>
						<g id='dot-12' className='dot'>
							<circle cx='350.45' cy='361.93' r='7' fill='#fff'/>
							<circle cx='350.45' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='350.45' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-12' className='stop'>
						<text id='caption-13' className='caption' transform='rotate(-45 558.558 -177.402)' fontSize='14' fill='#00275e' fontWeight='700'>Højby</text>
						<g id='dot-13' className='dot'>
							<circle cx='281.98' cy='361.93' r='7' fill='#fff'/>
							<circle cx='281.98' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='281.98' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-13' className='stop'>
						<text id='caption-14' className='caption' transform='rotate(-45 524.318 -94.74)' fontSize='14' fill='#00275e' fontWeight='700'>Nyled</text>
						<g id='dot-14' className='dot'>
							<circle cx='213.51' cy='361.93' r='7' fill='#fff'/>
							<circle cx='213.51' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='213.51' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g className='start-stop'>
						<circle id='dot-15' className='dot' cx='145.04' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-15' className='caption' transform='rotate(-45 490.348 -12.729)' fontSize='14' fill='#00275e' fontWeight='700'>Nykøbing Sjælland</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Odsherred;
