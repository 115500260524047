import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Hornbaekbanen = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1366 768'>
			<g id='hornbaekbanen' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M145.04 361.93h958.61'/>
				<g className='stations'>
					<g className='start-stop'>
						<circle className='dot' cx='145.04' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text className='caption' transform='rotate(-45 490.348 -12.729)' fontSize='14' fill='#00275e' fontWeight='700'>Gilleleje</text>
					</g>
					<g className='stop'>
						<text id='caption-2' className='caption' transform='rotate(-45 518.278 -80.158)' fontSize='14' fill='#00275e' fontWeight='700'>Gilleleje Øst</text>
						<g id='dot-2' className='dot'>
							<circle cx='201.43' cy='361.93' r='7' fill='#fff'/>
							<circle cx='201.43' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='201.43' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-2' className='stop'>
						<text id='caption-3' className='caption' transform='rotate(-45 546.473 -148.226)' fontSize='14' fill='#00275e' fontWeight='700'>Stæremosen</text>
						<g id='dot-3' className='dot'>
							<circle cx='257.82' cy='361.93' r='7' fill='#fff'/>
							<circle cx='257.82' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='257.82' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-3' className='stop'>
						<text id='caption-4' className='caption' transform='rotate(-45 574.668 -216.295)' fontSize='14' fill='#00275e' fontWeight='700'>Søborg</text>
						<g id='dot-4' className='dot'>
							<circle cx='314.2' cy='361.93' r='7' fill='#fff'/>
							<circle cx='314.2' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='314.2' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-4' className='stop'>
						<text id='caption-5' className='caption' transform='rotate(-45 602.863 -284.364)' fontSize='14' fill='#00275e' fontWeight='700'>Firhøj</text>
						<g id='dot-5' className='dot'>
							<circle cx='370.59' cy='361.93' r='7' fill='#fff'/>
							<circle cx='370.59' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='370.59' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-5' className='stop'>
						<text id='caption-6' className='caption' transform='rotate(-45 631.058 -352.433)' fontSize='14' fill='#00275e' fontWeight='700'>Dronningmølle</text>
						<circle id='dot-6' className='dot' cx='426.98' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-6' className='stop'>
						<text id='caption-7' className='caption' transform='rotate(-45 659.253 -420.501)' fontSize='14' fill='#00275e' fontWeight='700'>Kildekrog</text>
						<g id='dot-7' className='dot'>
							<circle cx='483.37' cy='361.93' r='7' fill='#fff'/>
							<circle cx='483.37' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='483.37' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-7' className='stop'>
						<text id='caption-8' className='caption' transform='rotate(-45 687.448 -488.57)' fontSize='14' fill='#00275e' fontWeight='700'>Horneby Sand</text>
						<g id='dot-8' className='dot'>
							<circle cx='539.76' cy='361.93' r='7' fill='#fff'/>
							<circle cx='539.76' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='539.76' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-8' className='stop'>
						<text id='caption-9' className='caption' transform='rotate(-45 715.638 -556.627)' fontSize='14' fill='#00275e' fontWeight='700'>Hornbæk</text>
						<circle id='dot-9' className='dot' cx='596.15' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-9' className='stop'>
						<text id='caption-10' className='caption' transform='rotate(-45 743.833 -624.696)' fontSize='14' fill='#00275e' fontWeight='700'>Karinebæk</text>
						<g id='dot-10' className='dot'>
							<circle cx='652.54' cy='361.93' r='7' fill='#fff'/>
							<circle cx='652.54' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='652.54' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-10' className='stop'>
						<text id='caption-11' className='caption' transform='rotate(-45 772.028 -692.764)' fontSize='14' fill='#00275e' fontWeight='700'>Saunte</text>
						<g id='dot-11' className='dot'>
							<circle cx='708.93' cy='361.93' r='7' fill='#fff'/>
							<circle cx='708.93' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='708.93' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-11' className='stop'>
						<text id='caption-12' className='caption' transform='rotate(-45 800.223 -760.833)' fontSize='14' fill='#00275e' fontWeight='700'>Skibstrup</text>
						<g id='dot-12' className='dot'>
							<circle cx='765.32' cy='361.93' r='7' fill='#fff'/>
							<circle cx='765.32' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='765.32' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-12' className='stop'>
						<text id='caption-13' className='caption' transform='rotate(-45 828.418 -828.902)' fontSize='14' fill='#00275e' fontWeight='700'>Ålsgårde</text>
						<circle id='dot-13' className='dot' cx='821.71' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-13' className='stop'>
						<text id='caption-14' className='caption' transform='rotate(-45 856.613 -896.97)' fontSize='14' fill='#00275e' fontWeight='700'>Hellebæk</text>
						<g id='dot-14' className='dot'>
							<circle cx='878.1' cy='361.93' r='7' fill='#fff'/>
							<circle cx='878.1' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='878.1' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-14' className='stop'>
						<text id='caption-15' className='caption' transform='rotate(-45 884.808 -965.04)' fontSize='14' fill='#00275e' fontWeight='700'>Højstrup</text>
						<g id='dot-15' className='dot'>
							<circle cx='934.49' cy='361.93' r='7' fill='#fff'/>
							<circle cx='934.49' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='934.49' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-15' className='stop'>
						<text id='caption-16' className='caption' transform='rotate(-45 912.018 -1030.73)' fontSize='14' fill='#00275e' fontWeight='700'>Marienlyst</text>
						<g id='dot-16' className='dot'>
							<circle cx='990.87' cy='361.93' r='7' fill='#fff'/>
							<circle cx='990.87' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='990.87' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-16' className='stop'>
						<text id='caption-17' className='caption' transform='rotate(-45 941.198 -1101.177)' fontSize='14' fill='#00275e' fontWeight='700'>Grønnehave</text>
						<circle id='dot-17' className='dot' cx='1047.26' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='end-stop'>
						<circle id='dot-18' className='dot' cx='1103.65' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-18' className='caption' transform='rotate(-45 968.343 -1166.71)' fontSize='14' fill='#00275e' fontWeight='700'>Helsingør</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Hornbaekbanen;
