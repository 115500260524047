import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Tisvildeleje = ({ svgRef, routeRef, secondaryRouteRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1366 768'>
			<g id='tisvildeleje-hilleroed' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M145.04 361.93h697.13'/>
				<path ref={secondaryRouteRef} className='secondary-route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M842.26 361.93h261.39'/>
				<g className='stations'>
					<g className='start-stop'>
						<circle className='dot' cx='145.04' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text className='caption' transform='rotate(-45 490.348 -12.729)' fontSize='14' fill='#00275e' fontWeight='700'>Tisvildeleje</text>
					</g>
					<g className='stop'>
						<text id='caption-2' className='caption' transform='rotate(-45 533.658 -117.288)' fontSize='14' fill='#00275e' fontWeight='700'>Godhavn</text>
						<g id='dot-2' className='dot'>
							<circle cx='232.18' cy='361.93' r='7' fill='#fff'/>
							<circle cx='232.18' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='232.18' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-2' className='stop'>
						<text id='caption-3' className='caption' transform='rotate(-45 577.228 -222.476)' fontSize='14' fill='#00275e' fontWeight='700'>Holløse</text>
						<g id='dot-3' className='dot'>
							<circle cx='319.33' cy='361.93' r='7' fill='#fff'/>
							<circle cx='319.33' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='319.33' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-3' className='stop'>
						<circle id='dot-4' className='dot' cx='406.48' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-4' className='caption' transform='rotate(-45 620.803 -327.675)' fontSize='14' fill='#00275e' fontWeight='700' letterSpacing='-.05em'>Vejby</text>
					</g>
					<g id='stop-4' className='stop'>
						<text id='caption-5' className='caption' transform='rotate(-45 664.378 -432.874)' fontSize='14' fill='#00275e' fontWeight='700'>Ørby</text>
						<g id='dot-5' className='dot'>
							<circle cx='493.62' cy='361.93' r='7' fill='#fff'/>
							<circle cx='493.62' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='493.62' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-5' className='stop'>
						<text id='caption-6' className='caption' transform='rotate(-45 707.953 -538.074)' fontSize='14' fill='#00275e' fontWeight='700'>Troldebakkerne</text>
						<g id='dot-6' className='dot'>
							<circle cx='580.77' cy='361.93' r='7' fill='#fff'/>
							<circle cx='580.77' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='580.77' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-6' className='stop'>
						<circle id='dot-7' className='dot' cx='667.92' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-7' className='caption' transform='rotate(-45 751.523 -643.26)' fontSize='14' fill='#00275e' fontWeight='700'>Helsinge</text>
					</g>
					<g id='stop-7' className='stop'>
						<text id='caption-8' className='caption' transform='rotate(-45 795.098 -748.46)' fontSize='14' fill='#00275e' fontWeight='700'>Duemose</text>
						<g id='dot-8' className='dot'>
							<circle cx='755.07' cy='361.93' r='7' fill='#fff'/>
							<circle cx='755.07' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='755.07' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-8' className='intermediate-stop'>
						<text id='caption-9' className='caption' transform='rotate(-45 838.673 -853.66)' fontSize='14' fill='#00275e' fontWeight='700'>Kagerup</text>
						<g id='dot-9' className='dot'>
							<circle cx='842.21' cy='361.93' r='7' fill='#fff'/>
							<circle cx='842.21' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='842.21' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-9' className='stop'>
						<text id='caption-10' className='caption' transform='rotate(-45 882.243 -958.847)' fontSize='14' fill='#00275e' fontWeight='700'>Gribsø</text>
						<g id='dot-10' className='dot'>
							<circle cx='929.36' cy='361.93' r='7' fill='#fff'/>
							<circle cx='929.36' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='929.36' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-10' className='stop'>
						<circle id='dot-11' className='dot' cx='1016.51' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-11' className='caption' transform='rotate(-45 925.818 -1064.046)' fontSize='14' fill='#00275e' fontWeight='700'>Slotspavillonen</text>
					</g>
					<g className='end-stop'>
						<circle id='dot-12' className='dot' cx='1103.65' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text id='caption-12' className='caption' transform='rotate(-45 969.393 -1169.246)' fontSize='14' fill='#00275e' fontWeight='700'>Hillerød</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Tisvildeleje;
