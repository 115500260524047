import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const HornbaekbanenMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 414 768'>
			<g id='hornbaekbanen-mobile' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className='route' fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M207 37.24v678.37'/>
				<g className='stations'>
					<g className='start-stop'>
						<text className='caption' transform='translate(224.09 42.19)' fontSize='14' fill='#00275e' fontWeight='700'>Gilleleje</text>
						<circle className='dot' cx='207' cy='37.24' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='stop'>
						<text id='caption-2' className='caption' transform='translate(224.09 82.1)' fontSize='14' fill='#00275e' fontWeight='700'>Gilleleje Øst</text>
						<g id='dot-2' className='dot'>
							<circle cx='207' cy='77.14' r='7' fill='#fff'/>
							<circle cx='207' cy='77.14' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='77.14' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-2' className='stop'>
						<text id='caption-3' className='caption' transform='translate(224.09 122)' fontSize='14' fill='#00275e' fontWeight='700'>Stæremosen</text>
						<g id='dot-3' className='dot'>
							<circle cx='207' cy='117.05' r='7' fill='#fff'/>
							<circle cx='207' cy='117.05' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='117.05' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-3' className='stop'>
						<text id='caption-4' className='caption' transform='translate(224.09 161.91)' fontSize='14' fill='#00275e' fontWeight='700'>Søborg</text>
						<g id='dot-4' className='dot'>
							<circle cx='207' cy='156.95' r='7' fill='#fff'/>
							<circle cx='207' cy='156.95' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='156.95' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-4' className='stop'>
						<text id='caption-5' className='caption' transform='translate(224.09 201.81)' fontSize='14' fill='#00275e' fontWeight='700'>Firhøj</text>
						<g id='dot-5' className='dot'>
							<circle cx='207' cy='196.86' r='7' fill='#fff'/>
							<circle cx='207' cy='196.86' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='196.86' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-5' className='stop'>
						<text id='caption-6' className='caption' transform='translate(224.09 241.72)' fontSize='14' fill='#00275e' fontWeight='700'>Dronningmølle</text>
						<circle id='dot-6' className='dot' cx='207' cy='236.76' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-6' className='stop'>
						<text id='caption-7' className='caption' transform='translate(224.09 281.62)' fontSize='14' fill='#00275e' fontWeight='700'>Kildekrog</text>
						<g id='dot-7' className='dot'>
							<circle cx='207' cy='276.66' r='7' fill='#fff'/>
							<circle cx='207' cy='276.66' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='276.66' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-7' className='stop'>
						<text id='caption-8' className='caption' transform='translate(224.09 321.52)' fontSize='14' fill='#00275e' fontWeight='700'>Horneby Sand</text>
						<g id='dot-8' className='dot'>
							<circle cx='207' cy='316.57' r='7' fill='#fff'/>
							<circle cx='207' cy='316.57' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='316.57' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-8' className='stop'>
						<text id='caption-9' className='caption' transform='translate(224.09 361.43)' fontSize='14' fill='#00275e' fontWeight='700'>Hornbæk</text>
						<circle id='dot-9' className='dot' cx='207' cy='356.47' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-9' className='stop'>
						<text id='caption-10' className='caption' transform='translate(224.09 401.33)' fontSize='14' fill='#00275e' fontWeight='700'>Karinebæk</text>
						<g id='dot-10' className='dot'>
							<circle cx='207' cy='396.38' r='7' fill='#fff'/>
							<circle cx='207' cy='396.38' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='396.38' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-10' className='stop'>
						<text id='caption-11' className='caption' transform='translate(224.09 441.24)' fontSize='14' fill='#00275e' fontWeight='700'>Saunte</text>
						<g id='dot-11' className='dot'>
							<circle cx='207' cy='436.28' r='7' fill='#fff'/>
							<circle cx='207' cy='436.28' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='436.28' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-11' className='stop'>
						<text id='caption-12' className='caption' transform='translate(224.09 481.14)' fontSize='14' fill='#00275e' fontWeight='700'>Skibstrup</text>
						<g id='dot-12' className='dot'>
							<circle cx='207' cy='476.18' r='7' fill='#fff'/>
							<circle cx='207' cy='476.18' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='476.18' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-12' className='stop'>
						<text id='caption-13' className='caption' transform='translate(224.09 521.04)' fontSize='14' fill='#00275e' fontWeight='700'>Ålsgårde</text>
						<circle id='dot-13' className='dot' cx='207' cy='516.09' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g id='stop-13' className='stop'>
						<text id='caption-14' className='caption' transform='translate(224.09 560.95)' fontSize='14' fill='#00275e' fontWeight='700'>Hellebæk</text>
						<g id='dot-14' className='dot'>
							<circle cx='207' cy='555.99' r='7' fill='#fff'/>
							<circle cx='207' cy='555.99' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='555.99' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-14' className='stop'>
						<text id='caption-15' className='caption' transform='translate(224.09 600.85)' fontSize='14' fill='#00275e' fontWeight='700'>Højstrup</text>
						<g id='dot-15' className='dot'>
							<circle cx='207' cy='595.9' r='7' fill='#fff'/>
							<circle cx='207' cy='595.9' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='595.9' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-15' className='stop'>
						<text id='caption-16' className='caption' transform='translate(224.09 640.76)' fontSize='14' fill='#00275e' fontWeight='700'>Marienlyst</text>
						<g id='dot-16' className='dot'>
							<circle cx='207' cy='635.8' r='7' fill='#fff'/>
							<circle cx='207' cy='635.8' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='207' cy='635.8' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g id='stop-16' className='stop'>
						<text id='caption-17' className='caption' transform='translate(224.09 680.66)' fontSize='14' fill='#00275e' fontWeight='700'>Grønnehave</text>
						<circle id='dot-17' className='dot' cx='207' cy='675.7' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='end-stop'>
						<text id='caption-18' className='caption' transform='translate(224.09 718.56)' fontSize='14' fill='#00275e' fontWeight='700'>Helsingør</text>
						<circle id='dot-18' className='dot' cx='207' cy='713.61' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default HornbaekbanenMobile;
