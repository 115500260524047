import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Lolland = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1366 768'>
			<g id='lolland' fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path className='route' ref={routeRef} fill='none' stroke='#6593d5' strokeMiterlimit='10' strokeWidth='2' d='M145.04 361.93h958.61'/>
				<g className='stations'>
					<g className='start-stop'>
						<circle className='dot' cx='145.04' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
						<text className='caption' transform='rotate(-45 490.348 -12.729)' fontSize='14' fill='#00275e' fontWeight='700'>Nakskov</text>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 549.998 -156.736)' fontSize='14' fill='#00275e' fontWeight='700' letterSpacing='-.02em'>Avnede</text>
						<g className='dot'>
							<circle cx='264.86' cy='361.93' r='7' fill='#fff'/>
							<circle cx='264.86' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='264.86' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 609.913 -301.384)' fontSize='14' fill='#00275e' fontWeight='700'>Søllested</text>
						<circle className='dot' cx='384.69' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 669.823 -446.02)' fontSize='14' fill='#00275e' fontWeight='700'>Ryde</text>
						<g className='dot'>
							<circle cx='504.52' cy='361.93' r='7' fill='#fff'/>
							<circle cx='504.52' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='504.52' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 729.738 -590.667)' fontSize='14' fill='#00275e' fontWeight='700'>Maribo</text>
						<circle className='dot' cx='624.35' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 789.653 -735.315)' fontSize='14' fill='#00275e' fontWeight='700'>Sakskøbing</text>
						<circle className='dot' cx='744.17' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
					<g className='stop'>
						<g className='dot'>
							<circle cx='864' cy='361.93' r='7' fill='#fff'/>
							<circle cx='864' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='864' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
						<text className='caption' transform='rotate(-45 849.563 -879.95)' fontSize='14' fill='#00275e' fontWeight='700'>Grænge</text>
					</g>
					<g className='stop'>
						<text className='caption' transform='rotate(-45 909.478 -1024.598)' fontSize='14' fill='#00275e' fontWeight='700'>Øster Toreby</text>
						<g className='dot'>
							<circle cx='983.83' cy='361.93' r='7' fill='#fff'/>
							<circle cx='983.83' cy='361.93' r='7' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3'/>
							<circle cx='983.83' cy='361.93' r='7' fill='none' stroke='#6593d5' strokeMiterlimit='10'/>
						</g>
					</g>
					<g className='end-stop'>
						<text className='caption' transform='rotate(-45 969.393 -1169.246)' fontSize='14' fill='#00275e' fontWeight='700'>Nykøbing Falster</text>
						<circle className='dot' cx='1103.65' cy='361.93' r='7' fill='#6593d5' stroke='#fff' strokeMiterlimit='10'/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Lolland;
