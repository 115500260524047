import React, { useContext, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';
import Meta from '../components/meta';
import Oestbanen from '../routes/oestbanen';
import Lolland from '../routes/lolland';
import { PageData } from '../types/global.types';
import { IRoutePage } from '../types/heartcore.types';
import ModulesController from '../components/modules-controller';
import LokaltogContext from '../context/context';
import ArrowIcon from '../components/svg/arrow-icon';
import RawHtml from '../components/raw-html';
import Notifications from '../components/notifications';
import Toelloese from '../routes/toelloese';
import Odsherred from '../routes/odsherred';
import Naerum from '../routes/naerum';
import Frederiksvaerk from '../routes/frederiksvaerk';
import Lillenord from '../routes/lillenord';
import Hornbaekbanen from '../routes/hornbaekbanen';
import Gilleleje from '../routes/gilleleje-hilleroed';
import Tisvildeleje from '../routes/tisvildeleje-hilleroed';
import LinkWithPageTransition from '../components/link-with-page-transition';
import LollandMobile from '../routes/lolland-mobile';
import ToelloeseMobile from '../routes/toelloese-mobile';
import OdsherredMobile from '../routes/odsherred-mobile';
import NaerumMobile from '../routes/naerum-mobile';
import FrederiksvaerkMobile from '../routes/frederiksvaerk-mobile';
import LillenordMobile from '../routes/lillenord-mobile';
import HornbaekbanenMobile from '../routes/hornbaekbanen-mobile';
import GillelejeMobile from '../routes/gilleleje-hilleroed-mobile';
import TisvildelejeMobile from '../routes/tisvildeleje-hilleroed-mobile';
import OestbanenMobile from '../routes/oestbanen-mobile';
import { useContent } from '../hooks/use-content';
import { getCleanUrl } from '../utils/shared-build-runtime-utils';
import { transitionBackToMap, TRANSITION_DURATION } from '../utils/animations/routes-map-transitions';
import { dateFormatter, dateIsSet } from '../utils/utils';

const RouteSVGComponent = { 
	'oestbanen': Oestbanen,
	'toelloese': Toelloese,
	'odsherred': Odsherred,
	'lolland': Lolland,
	'naerum': Naerum,
	'frederiksvaerk': Frederiksvaerk,
	'lillenord': Lillenord,
	'gilleleje-hilleroed': Gilleleje,
	'tisvildeleje-hilleroed': Tisvildeleje,
	'hornbaekbanen': Hornbaekbanen,
	'oestbanen-mobile': OestbanenMobile,
	'toelloese-mobile': ToelloeseMobile,
	'odsherred-mobile': OdsherredMobile,
	'lolland-mobile': LollandMobile,
	'naerum-mobile': NaerumMobile,
	'frederiksvaerk-mobile': FrederiksvaerkMobile,
	'lillenord-mobile': LillenordMobile,
	'gilleleje-hilleroed-mobile': GillelejeMobile,
	'tisvildeleje-hilleroed-mobile': TisvildelejeMobile,
	'hornbaekbanen-mobile': HornbaekbanenMobile,
};

const RoutePage = ({ pageContext }:PageData<IRoutePage>) => {
	const lokaltogContext = useContext(LokaltogContext);
	const { meta, parent, route, modules, timetableUrl, operationalChanges } =  useContent<IRoutePage>(pageContext, lokaltogContext.rootNodeUrl);
	const { number, title, line, emergencyInformation, hasStopsUponRequest } = route || pageContext.route;
	const routeRef = useRef(null);
	const secondaryRouteRef = useRef(null);
	const svgRef = useRef(null);
	const headerWrapperRef = useRef(null);
	const [ stopsUponRequestLink, setStopsUponRequestLink ] = useState(null);

	const isMobile = () => {
		return lokaltogContext.windowWidth < 768;
	};

	useEffect(() => {
		if(hasStopsUponRequest) {
			const stopsUponRequestLinkCaption = lokaltogContext.tr('Route.StopOnRequestCaption');
			let link = lokaltogContext.tr('Route.StopOnRequestPageLink');
			if(link.includes(location.origin)) {
				link = getCleanUrl(link, lokaltogContext.rootNodeUrl);
			}
			const replaceStartMarker = stopsUponRequestLinkCaption.split('#<#').join(`<a className='link' href='${link}'>`);
			const replaceEndMarker = replaceStartMarker.split('#>#').join('</a>');
			setStopsUponRequestLink(replaceEndMarker);
		}
	},[]);

	const SVG = RouteSVGComponent[`${line}${isMobile() ? '-mobile' : ''}`];
	const routeSvg = <SVG svgRef={svgRef} routeRef={routeRef} secondaryRouteRef={secondaryRouteRef} />;

	return (
		<div className='routes'>
			<Meta {...meta} title={`${number} ${title}`} />
			<div className='route__container box-wide'>
				<div ref={headerWrapperRef} className='route__headline-wrapper'>
					<div className='link--go-back'>
						<div className='link-icon'><ArrowIcon /></div>
						<TransitionLink 
							className='route__go-back-link link-with-underline'
							to={parent?.url || pageContext.parent.url}
							exit={{
								length: TRANSITION_DURATION + .2,
								zIndex: 1,
							}}
							entry={{
								zIndex: 0,
								length: TRANSITION_DURATION + .2,
								delay: 0.3,
							}}
							trigger={async pages => {
								const entry = await pages.entry;
								const exit = await pages.exit;
								const destinationSvgEl = entry.node.querySelector('.routes__map-svg');
								const destinationContentContainer = entry.node.querySelector('.routes__content-container');
								
								const continueTransitioning = await transitionBackToMap(headerWrapperRef.current,
									svgRef.current, routeRef.current, secondaryRouteRef?.current, destinationSvgEl, line, destinationContentContainer);

								await entry.visible;

								continueTransitioning();

								gsap.from([entry.node.querySelectorAll('.fade-container'), entry.node.querySelector('.footer')], { opacity: 0, duration: .4});
								gsap.to([exit.node.querySelectorAll('.fade-container'), exit.node.querySelector('.footer')], { opacity: 0, duration: .4});
							}}
						>{lokaltogContext.tr('Global.GoBackTo')} {parent?.name}</TransitionLink>
					</div>	
					{!isMobile() ? <h1 className='route__headline'>{number} {title}</h1> : ''}
				</div>
				{routeSvg}
				{isMobile() ? <h1 className='route__headline'>{number} {title}</h1> : ''}
				<div className='fade-container route__info'>
					{stopsUponRequestLink && <div className='route__info__stop-upon-request'><RawHtml content={stopsUponRequestLink} /></div>}
					{emergencyInformation && <Notifications notifications={[{route: route}]} contentTypeAlias={null} triggerHeightUpdateAfter={TRANSITION_DURATION + .2} />}
					{operationalChanges?.length > 0 
						?
						<div className='route__info__operational-changes'>
							<h3>{lokaltogContext.tr('Route.OperationalChanges')}</h3>
							<div  className='route__info__operational-changes__item-list'>
								{operationalChanges
									.filter(foc => foc.url !== '/alt-om-rejsen/planlagte-aendringer/togbus-nykobing-f-graenge/') //filter out a "ghost page" that Umbraco can't seem to locate
									.map(oc => (
										<LinkWithPageTransition url={oc.url} key={oc.id} classes='route__info__operational-changes__item'>
											<h6 className='link'>
												{dateIsSet(oc.startDate) 
													? `${dateFormatter(oc.startDate)} ${dateIsSet(oc.endDate) 
														? ` - ${dateFormatter(oc.endDate)}` : ''}`
													: ''
												}
											</h6>
											<div>{oc.overviewDescription}</div>
										</LinkWithPageTransition>
									))}
							</div>
						</div>
						:
						null
					}
					{timetableUrl && <a className='button' download href={timetableUrl} target='_blank' rel='noreferrer'>{lokaltogContext.tr('Route.DownloadTimetable')}</a>}
					<a className='route__info__rejseplanen-link link' href={lokaltogContext.tr('Route.RejseplanenLinkUrl')} target='_blank' rel='noreferrer' >{lokaltogContext.tr('Route.RejseplanenLinkCaption')}</a>
				</div>
			</div>
			<div className='fade-container'><ModulesController modules={modules} /></div>
		</div>
	);
};

export default RoutePage;
